
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import AnchorLink from '@components/AnchorLink';
import { HOME_URL, LOGIN } from '@constants/routes';
import useTranslation from 'next-translate/useTranslation';
import Button from '@/src/components/Button';
import Logo from '@components/Logo';
import { trackEvent } from '@utils/analytics';
import s from './AuthHeader.module.scss';
const propTypes = {
    pageName: PropTypes.string,
    showMobileLogin: PropTypes.bool,
    showBackBtn: PropTypes.bool
};
const AuthHeader = ({ pageName = '', showMobileLogin = false, showBackBtn = false }) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const navigateBack = () => {
        if (window.history.length > 1) {
            router.back();
        }
        else {
            router.push(HOME_URL);
        }
    };
    return (<div className={s.container}>
      {showBackBtn && (<div className={[s.back, s.backBtn].join(' ')} onClick={() => navigateBack()} onKeyDown={(e) => {
                /* istanbul ignore else */
                if (e.key === 'Enter')
                    navigateBack();
            }} data-testid="back-button" role="button" tabIndex="0"/>)}
      <div className={s.wwlogo}>
        <AnchorLink title="Wizarding World logo" href={HOME_URL} onClick={() => trackEvent('Wizarding World Logo Clicked', { location: pageName })}>
          <Logo />
        </AnchorLink>
      </div>
      {showMobileLogin && (<Button label={t('auth:login-short')} data-testid="top-login-button" size="sm" className={s.loginButton} onClick={() => {
                router.push(LOGIN);
            }}/>)}
    </div>);
};
AuthHeader.propTypes = propTypes;
export default AuthHeader;

    async function __Next_Translate__getStaticProps__194cc797a95__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Auth/components/AuthHeader/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc797a95__ as getStaticProps }
  